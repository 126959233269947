<template>
  <div class="activity page-container">
    <div style="height:280px"><BannerSwiper /></div>
    <ExaminationFilter :initData="filterData" @change="getFilterData"/>
    <ExaminationListWrapper :nums="20" pagination :filterData="filterData" style="margin-top:26px" />
  </div>
</template>
<script>//activity
import BannerSwiper from 'components/bannerSwiper.vue'
import ExaminationFilter from "components/examination/examinationFilter"
import ExaminationListWrapper from "components/examination/examinationListWrapper"
export default {
  name:"pageActivities",
  data:()=>({
    filterData:{
      search_content:""
    }
  }),
  created(){
    let filterData = {...this.filterData}
    filterData.search_content = this.$route.params.search_content||"" 
    this.filterData = filterData
  },
  methods:{
    getFilterData(data){
      this.filterData = data
    }
  },
  computed:{
  },
  watch:{},
  components: { BannerSwiper,ExaminationListWrapper,ExaminationFilter },
}
</script>
<style lang="scss">
// @import "constants/style/public";
</style>