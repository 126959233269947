<template>
  <div>
    <a target="_blank" :href="link"  class="activity-card toActivityDetail">
      <el-image :src="data.des_link" class="activity-card-img" />
      <div class="text-wrapper">
        <div class="title ellipsis-2">{{data.career_fair_name||"2021年衢州人才云聘会"}}</div>
        <div class="time">考生报名时间：{{time||"2021.06.07 - 2021.07.07"}}</div>
        <div v-if="!isExam" class="adress ellipsis">地点：{{data.address||"线上"}}</div>
      </div>
    </a>
    
  </div>
</template>
<script>
export default {
  name:"ActivityCard",
  props:['data','link','isExam'],
  data:()=>({
    
  }),
  methods:{},
  computed:{
    time(){
      if(this.isExam){
        if( this.data.personal_apply_time){
          if(this.data.personal_ddl_time) return `${this.$dayjs(this.data.personal_apply_time).format("YYYY.MM.DD")} - ${this.$dayjs(this.data.personal_ddl_time).format("YYYY.MM.DD")}`
          else return `${this.$dayjs(this.data.personal_apply_time).format("YYYY.MM.DD")} - 待定`
        }else{
          if(this.data.personal_ddl_time) return `${this.$dayjs(this.data.personal_ddl_time).format("YYYY.MM.DD")} 结束`
          else return `待定`
        }
      }else{
         if( this.data.start_time){
          if(this.data.end_time) return `${this.$dayjs(this.data.start_time).format("YYYY.MM.DD")} - ${this.$dayjs(this.data.end_time).format("YYYY.MM.DD")}`
          else return `${this.$dayjs(this.data.start_time).format("YYYY.MM.DD")} - 待定`
        }else{
          if(this.data.end_time) return `${this.$dayjs(this.data.end_time).format("YYYY.MM.DD")} 结束`
          else return `待定`
        }
      }
    }
  },
  watch:{},

}
</script>
<style lang="scss">
@import "constants/style/public";
.activity-card{
  display: block;
  background-color: var(--defaultBg);
  width: 288px;
  height: 270px;
  border: 1px solid var(--borderLineColor,#F4F4F4);
  &:hover{
    >.text-wrapper>.title{
      color: var(--moreColor,#666);
    }
  }
  &.toActivityDetail{
    cursor: pointer;
  }
  .activity-card-img{
    width: 100%;
    height: 163px;
    margin-bottom: 5px;
  }
  .text-wrapper{
    padding: 0 16px;
    text-align: left;
    font-size:$little;
    color: $cardsubTextColor;
    .title{
      width: 100%;
      font-size: $medium;
      color: $titleColor;
      font-weight: $weight-medium;
      margin-bottom: 9px;
      
    }
    .time{
      margin-bottom: 6px;
    }
  }
}
</style>